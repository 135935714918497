<template>
  <div class="border-round-lg border-1"
       :class="`${typeWall === position.FRONT ? 'border-pink-500' : typeWall === position.LEFT ? 'border-cyan-500' : 'border-indigo-500'}`">
    <div class="surface-border border-round-top-lg justify-content-center"
         :class="`${typeWall === position.FRONT ? 'bg-pink-300' : typeWall === position.LEFT ? 'bg-cyan-300' : 'bg-indigo-300'}`">
      <h5 class="text-center uppercase">BKS</h5>
    </div>
    <div class="flex flex-row flex-wrap p-2">
      <div class="w-5 pr-3">

        <InputGroup class="h-2rem">
          <InputGroupAddon class="w-7 uppercase font-medium text-xs text-700 surface-400">Názov</InputGroupAddon>
          <InputGroupAddon class="w-3 uppercase font-medium text-xs text-700 surface-400">Zadaj</InputGroupAddon>
          <InputGroupAddon class="w-2 uppercase font-medium text-xs text-700 surface-400">Jednotky</InputGroupAddon>
        </InputGroup>
        <row-input name="width"
                   id="width"
                   required="true"
                   labelFront="Šírka balkóna/Lodžie"
                   labelBack="[mm]"
                   :defaultValue="this.wall.width"
                   type="number"
                   ref="width"
                   @new-wall-value="ChangeInput"
        >
        </row-input>

        <row-input name="height"
                   id="height"
                   required="true"
                   labelFront="Výša balkóna/Lodžie"
                   labelBack="[mm]"
                   :defaultValue="this.wall.height"
                   type="number"
                   ref="height"
                   :validationAlert="!this.validationOK"
                   @new-wall-value="ChangeInput">
        </row-input>
        <row-input name="framesCount"
                   id="framesCount"
                   required="true"
                   labelFront="Počet krídel skiel"
                   labelBack="[ks]"
                   :defaultValue="this.wall.framesCount"
                   type="number"
                   ref="framesCount"
                   @new-wall-value="ChangeInput">
        </row-input>

        <row-input
            name="openingDirection"
            id="openingDirection"
            labelFront="Otváranie skiel"
            labelBack="[-]"
            :defaultValue="this.wall.openingDirection"
            type="select"
            :option="this.store.opening_direction_bks"
            ref="openingDirection"
            @new-wall-value="ChangeInput">
        </row-input>

        <div class="formgroup-inline flex w-full align-items-end items-end justify-content-end" v-if="this.store.order.type.code == TypeCode.NB_F_D">
          <div class="bg-green-100 m-2 p-2" v-if="this.wall.openingDirection == OpeningDirection.LEFT">Pozícia dverí  <strong>VPRAVO</strong></div>
          <div class="bg-yellow-100 m-2 p-2" v-else>Pozícia dverí <strong>VĽAVO</strong></div>
        </div>

        <div class="formgroup-inline w-full">
          <row-input name="glassTemperedThickness"
                     id="glassTemperedThickness"
                     labelFront="Hrúbka kalených skiel"
                     labelBack="[mm]"
                     :defaultValue="this.wall.glassTemperedThickness"
                     type="select"
                     :option="this.store.glass_tempered_thickness"
                     ref="glassTemperedThickness"
                     @new-wall-value="ChangeInput">
          </row-input>
          <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">(výška balkóna do 1.7m
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem "> 6
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>
          </div>
          <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">(výška balkóna od 1.7m do 2.2m
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem"> 8
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>
          </div>
          <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">(výška balkóna nad 2.2m
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem">10
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>
          </div>
        </div>

        <row-input name="caseType"
                   id="caseType"
                   labelFront="Výška púzdra"
                   labelBack="[mm]"
                   :defaultValue="this.wall.caseType"
                   type="select"
                   :option="this.store.case_types"
                   ref="caseType"
                   @new-wall-value="ChangeInput">
        </row-input>

        <row-input v-if="this.wall.caseType == Models.CaseType.NONE"
                   name="caseThickness"
                   id="caseThickness"
                   labelFront="Odčítanie púzdra na sklo"
                   labelBack="[mm]"
                   :defaultValue="this.wall.caseThickness"
                   type="select"
                   :option="this.store.case_type_range_0"
                   ref="caseThickness"
                   @new-wall-value="ChangeInput">
        </row-input>
        <row-input v-if="this.wall.caseType == Models.CaseType.CASE60"
                   name="caseThickness"
                   id="caseThickness"
                   labelFront="Odčítanie púzdra na sklo"
                   labelBack="[mm]"
                   :defaultValue="this.wall.caseThickness"
                   type="select"
                   :option="this.store.case_type_range_60"
                   ref="caseThickness"
                   @new-wall-value="ChangeInput">
        </row-input>
        <row-input v-if="this.wall.caseType == Models.CaseType.CASE90"
                   name="caseThickness"
                   id="caseThickness"
                   labelFront="Odčítanie púzdra na sklo"
                   labelBack="[mm]"
                   :defaultValue="this.wall.caseThickness"
                   type="select"
                   :option="this.store.case_type_range_90"
                   ref="caseThickness"
                   @new-wall-value="ChangeInput">
        </row-input>
        <row-input name="anotherProfilesThickness"
                   id="anotherProfilesThickness"
                   labelFront="Odčítanie iných profilov"
                   labelBack="[mm]"
                   type="number"
                   :defaultValue="this.wall.anotherProfilesThickness"
                   ref="anotherProfilesThickness"
                   @new-wall-value="ChangeInput">
        </row-input>
        <div class="formgroup-inline pl-2 w-full">
          <div class="text-xs font-light text-500 w-11rem">(štandard pri BKS
          </div>
          <div class="text-xs font-light text-500 ml-2 w-3rem ">128
          </div>
          <div class="text-xs font-light text-500 ml-1">mm)
          </div>
        </div>

        <div class="formgroup-inline pl-2 w-full">
          <div class="text-xs font-light text-500 w-11rem">(profily s plastovými kolieskami
          </div>
          <div class="text-xs font-light text-500 ml-2 w-3rem ">170
          </div>
          <div class="text-xs font-light text-500 ml-1">mm)
          </div>
        </div>
        <div class="formgroup-inline pl-2 w-full">
          <div class="text-xs font-light text-500 w-11rem">(profily s oceľovými kolieskami
          </div>
          <div class="text-xs font-light text-500 ml-2 w-3rem">175, 177
          </div>
          <div class="text-xs font-light text-500 ml-1">mm)
          </div>
        </div>
        <!-- <div class="formgroup-inline pl-2 w-full">
                <div class="text-xs font-light text-500 w-11rem">
                </div>
                <div class="text-xs font-light text-500 ml-2 w-1rem">177
                </div>
                <div class="text-xs font-light text-500 ml-1">mm)
                </div>
            </div>
        -->
        <row-input name="capType"
                   id="capType"
                   labelFront="Typ krytiek"
                   labelBack="[mm]"
                   :defaultValue="this.wall.capType"
                   type="select"
                   :option="this.store.cap_type"
                   ref="capType"
                   @new-wall-value="ChangeInput">
        </row-input>
        <div class="formgroup-inline pl-2 w-full">
          <div class="text-xs font-light text-500 w-5rem">(čierne krytky
          </div>
          <div class="text-xs font-light text-500 ml-0 w-1rem">15
          </div>
          <div class="text-xs font-light text-500 ml-0">mm,
          </div>
          <div class="text-xs font-light text-500 w-5rem ml-2">sivé krytky
          </div>
          <div class="text-xs font-light text-500 ml-0 w-1rem">16
          </div>
          <div class="text-xs font-light text-500 ml-0">mm)
          </div>
        </div>

        <row-input name="profilesColor"
                   id="profilesColor"
                   labelFront="Farba profilov"
                   labelBack="[-]"
                   :defaultValue="this.wall.profilesColor"
                   type="text"
                   ref="profilesColor"
                   @new-wall-value="ChangeInput">
        </row-input>
        <row-input name="glazingType"
                   name2="glazingTypeAttribute"
                   id="glazingType"
                   labelFront="Stopsol"
                   labelBack="farba"
                   :defaultValue="this.wall.glazingType"
                   :defaultValue2="this.wall.glazingTypeAttribute"
                   type="select-select"
                   :option="this.store.glazing_type"
                   :option2="this.store.glazing_type_attribute"
                   ref="glazingType"
                   @new-wall-value="ChangeInput">
        </row-input>

        <row-input name="holes"
                   id="holes"
                   labelFront="Horné diery"
                   :defaultValue="this.wall.holes"
                   type="checkbox"
                   ref="holes"
                   @new-wall-value="ChangeInput">
        </row-input>

        <row-input
            name="handle"
            id="handle"
            labelFront="Kľučka"
            :defaultValue="this.wall.handle"
            type="checkbox"
            ref="handle"
            @new-wall-value="ChangeInput">
        </row-input>

        <InputGroup class="h-2rem mt-2">
          <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-gray-300">Poznámky</InputGroupAddon>
        </InputGroup>

        <InputGroup>

          <TextArea autoResize rows="2" class="w-full"
                    placeholder="Poznámka ku stene"
                    v-model="this.wall.comment"
                    style="background: #b8d6f0; text-align: left"
          />
        </InputGroup>
      </div>
      <div class="w-5 pr-3">
        <InputGroup class="h-2rem">
          <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-300">Výstupné údaje
          </InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-3rem">
          <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Predbežný výpočet šírky
            všetkých profilov
          </InputGroupAddon>
          <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{ this.wall.profilesLength }}</InputGroupAddon>
          <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2">
          <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka profilu
          </InputGroupAddon>
          <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{ this.wall.singleProfileLength }}
          </InputGroupAddon>
          <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <row-input name="manualSingleProfileLength"
                   id="manualSingleProfileLength"
                   labelFront="Zadaná šírka profilu"
                   labelBack="[mm]"
                   :defaultValue="this.wall.manualSingleProfileLength"
                   type="number"
                   ref="manualSingleProfileLength"
                   backgroundText="bg-gray-300"
                   @new-wall-value="ChangeInput">
        </row-input>

        <row-input
            name="customDoor"
            id="customDoor"
            labelFront="Vlastná šírka PROFILU dverí"
            :defaultValue="this.wall.customDoor"
            type="checkbox"
            ref="customDoor"
            @new-wall-value="ChangeInput">
        </row-input>

        <row-input v-if="this.wall.customDoor"
                   name="customDoorProfileWidth"
                   id="customDoorProfileWidth"
                   labelFront="Zadaná šírka PROFILU dverí"
                   labelBack="[mm]"
                   :defaultValue="this.wall.customDoorProfileWidth"
                   type="number"
                   ref="customDoorProfileWidth"
                   backgroundText="bg-gray-300"
                   @new-wall-value="ChangeInput">
        </row-input>

        <InputGroup class="h-2rem mt-2">
          <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start bg-gray-300">
            Prepočtová šírka
            <span v-if="this.getTotalLengthDiff() > 0" style="color: var(--red-500)">
              &nbsp;-{{ this.getTotalLengthDiff() }}mm!
              </span>
          </InputGroupAddon>
          <InputGroupAddon class="w-3  font-normal text-base text-700 ">
            <span :style="this.getTotalLengthDiff() > 0 ? 'color: var(--red-500)':''">
              {{ this.wall.totalProfilesLength }}
              </span>
          </InputGroupAddon>
          <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem">
          <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-100">Výpočet skutočnej šírky skla
          </InputGroupAddon>
        </InputGroup>

        <!--  BKS glasses iteration-->
        <InputGroup class="h-2rem" v-for="(g,i) in this.wall.bksGlasses" v-bind:key="g.id">
          <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">
            {{ i + 1 }}. sklo
            <strong v-if="g.door"> - dvere</strong>
          </InputGroupAddon>
          <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{ g.width }}</InputGroupAddon>
          <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>

        <InputGroup class="h-2rem mt-2">
          <InputGroupAddon class="w-7  font-medium text-base text-800 justify-content-start bg-red-100">Výpočet výšky
            skiel
          </InputGroupAddon>
          <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{ this.wall.computedGlassHeight }}
          </InputGroupAddon>
          <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2" v-if="this.wall.customDoor">
          <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka profilov bez dverí
          </InputGroupAddon>
          <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{ this.wall.centerRailingWidth }}
          </InputGroupAddon>
          <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>

      </div>
      <div class="w-2" v-if="this.store.order.id > 0">
        <a v-if="this.store.pdfProductionLink !== ''" :href="this.store.pdfProductionLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="Zobraziť výrobný list" text raised class="uppercase" icon="pi pi-eye"/>
        </a>

        <a v-if="this.store.pdfProductionPrintLink !== '' " :href="this.store.pdfProductionPrintLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="Tlačiť výrobný list" text raised class="uppercase" icon="pi pi-print" severity="success"/>
        </a>

        <a v-if="this.store.pdfBksProfileLink !== '' " :href="this.store.pdfBksProfileLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="Zobraziť BKS profil" text raised class="uppercase" icon="pi pi-eye"/>
        </a>

        <a v-if="this.store.pdfBksProfilePrintLink !== '' " :href="this.store.pdfBksProfilePrintLink" class=" mr-5 ml-5 mb-5" target="_blank">
          <PrimevueButton label="Tlačiť BKS profil" text raised class="uppercase" icon="pi pi-print"  severity="success"/>
        </a>

        <a v-if="this.store.dxfGlassesLink !== '' " :href="this.store.dxfGlassesLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="Stiahnuť DXF" text raised class="uppercase" icon="pi pi-download"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import Security from '../security';
//import notie from 'notie/dist/notie'
import {store} from '../store';
import RowInput from "@/components/forms/RowInput.vue";
import {BksGlass, Models, WallType} from '../models';
import TextArea from "primevue/textarea";
import PrimevueButton from "primevue/button";
import CaseType = Models.CaseType;
import Opening = Models.Opening;
import OpeningDirection = Models.OpeningDirection;
import TypeCode = Models.TypeCode;

//import {emiter} from '../../main';
const BksGlasses: BksGlass[] = [];
const wall: WallType = {
  id: 0,
  opening: Models.Opening.SLIDING,
  position: Models.Position.FRONT,
  width: 3500.0,
  height: 1366.0,
  framesCount: 6,
  glassTemperedThickness: "6",
  caseType: CaseType.NONE,
  caseThickness: 0.0,
  anotherProfilesThickness: 170,
  capType: 16,
  openingDirection: Models.OpeningDirection.LEFT,
  profilesColor: "",
  glazingType: "nie",
  glazingTypeAttribute: "číra",
  manualSingleProfileLength: 0,
  holes: false,
  handle: false,
  customDoor: false,
  doorPosition: "",
  customDoorProfileWidth: 0,
  profilesLength: 0,
  singleProfileLength: 0,
  totalProfilesLength: 0,
  sideGlassWidth: 0,
  sideGlassCount: 0,
  middleGlassWidth: 0,
  middleGlassCount: 0,
  computedGlassHeight: 0,
  bks: false,
  bksGlasses: BksGlasses,
  fixLeft: false,
  fixLeftWidth: 0,
  fixLeftProfileWidth: 0,
  fixLeftGlassWidth: 0,
  fixRight: false,
  fixRightWidth: 0,
  fixRightProfileWidth: 0,
  fixRightGlassWidth: 0,
  centerRailingWidth: 0,
  combined: false, // the wall main contains fixed part too
  barrier: true,
  comment: ""
}

export default {
  name: 'BksWall',
  computed: {
    TypeCode() {
      return TypeCode
    },
    OpeningDirection() {
      return OpeningDirection
    },
    Models() {
      return Models
    },
    Opening() {
      return Opening
    }
  },
  components: {
    PrimevueButton,
    TextArea,
    RowInput

  },
  props: {
    typeWall: String,
    isBarrier: Boolean,
    validationOK: Boolean,
    validationAttr: String,


  },
  data() {
    return {
      store,
      nameWall: String,
      nameTypeWall: String,
      wall: wall,
      opening: Models.Opening.SLIDING,
      key: 0 as Number,
      position: Models.Position,
      pdfProductionLink: String,
      pdfProductionPrintLink: String,
      pdfBksProfileLink: String,
      pdfBksProfilePrintLink: String,
      dxfGlassesLink: String,
      //holes: Boolean
    }
  },
  methods: {
    ChangeInput(data: any): void {

      switch (data.name) {
        case "opening":
          this.wall.opening = data.value;
          if (this.wall.opening == Opening.SLIDING || this.wall.opening == Opening.FIXED) {
            this.wall.combined = false
          }
          break;
        case "width":
          this.wall.width = data.value;
          break;
        case "height":
          this.wall.height = data.value;
          break;
        case "framesCount":
          this.wall.framesCount = data.value;
          break;
        case "glassTemperedThickness":
          this.wall.glassTemperedThickness = data.value;
          break;
        case "caseType":
          this.wall.caseType = data.value;
          // handle default value of this.wall.caseThickness
          if (!this.wall.caseThickness) {
            this.wall.caseThickness = 0.0
          }

          console.log('caseThickness before update', this.wall.caseThickness)
          console.log('caseType change val', this.wall.caseType)

          if (this.wall.caseType == CaseType.NONE && this.wall.caseThickness > 3.0) {
            this.wall.caseThickness = 0;
            console.log('updating caseThickness 0 to ', this.wall.caseThickness)
          } else if (this.wall.caseType == CaseType.CASE60 && this.wall.caseThickness < 40.0 || this.wall.caseThickness > 50.0) {
            this.wall.caseThickness = 40.0;
            console.log('updating caseThickness 60 to ', this.wall.caseThickness)
          } else if (this.wall.caseType == CaseType.CASE90 && this.wall.caseThickness < 50.0 || this.wall.caseThickness > 60.0) {
            this.wall.caseThickness = 70.0;
            console.log('updating caseThickness 60 to ', this.wall.caseThickness)
          }

          console.log('updated casethicknes: ', this.wall.caseThickness)
          break;
        case "caseThickness":
          this.wall.caseThickness = Number(data.value);
          break;
        case "anotherProfilesThickness":
          this.wall.anotherProfilesThickness = Number(data.value);
          break;
        case "capType":
          this.wall.capType = data.value;
          break;
        case "openingDirection":
          this.wall.openingDirection = data.value;
          break;
        case "profilesColor":
          this.wall.profilesColor = data.value;
          break;
        case "glazingType":
          this.wall.glazingType = data.value;
          break;
        case "glazingTypeAttribute":
          this.wall.glazingTypeAttribute = data.value;
          break;
        case "manualSingleProfileLength":
          this.wall.manualSingleProfileLength = data.value;
          break;
        case "holes":
          this.wall.holes = data.value;
          break;
        case "handle":
          this.wall.handle = data.value;
          break;
        case "comment":
          this.wall.comment = data.value;
          break;
        case "fixLeft":
          this.wall.fixLeft = data.value;
          break;
        case "fixLeftWidth":
          this.wall.fixLeftWidth = data.value;
          break;
        case "fixRight":
          this.wall.fixRight = data.value;
          break;
        case "fixRightWidth":
          this.wall.fixRightWidth = data.value;
          break;
        case "fixedGlassWidth":
          this.wall.fixedGlassWidth = data.value;
          break;
        case "fixedGlassHeight":
          this.wall.fixedGlassHeight = data.value;
          break;
        case "glassConnexThickness":
          this.wall.glassConnexThickness = data.value;
          break;
        case "glassConnexType":
          this.wall.glassConnexType = data.value;
          break;
        case "customDoor":
          this.wall.customDoor = data.value;
          break;
        case "customDoorProfileWidth":
          this.wall.customDoorProfileWidth = data.value;
          break;
        case "doorPosition":
          this.wall.doorPosition = data.value;
          break;
      }
      this.store.order.walls[this.key] = this.wall
      //this.$emit('change-wall')
    },
    getTotalLengthDiff() {
      let fixDiff = 0;
      if (this.wall.fixLeft) {
        fixDiff += this.wall.fixLeftWidth;
      }
      if (this.wall.fixRight) {
        fixDiff += this.wall.fixRightWidth;
      }
      return this.wall.width - this.wall.totalProfilesLength - fixDiff;
    },
    openingLabel(opening: Models.Opening) {
      return this.store.opening_labels[opening]
    }
    //console.log("opt:",this.glazingTypeValue);
  },
  mounted() {
  },
  beforeMount() {
    Security.requireToken();
    //console.log("idorder:", store.order.id)
    //this.$refs.width.focus()
    //this.$el.querySelector("#width").focus();
    switch (this.$props.typeWall) {
      case this.position.FRONT:
        this.nameTypeWall = this.store.front;
        this.nameWall = this.store.front_open;
        break;
      case this.position.LEFT:
        this.nameTypeWall = this.store.left;
        this.nameWall = this.store.side_open;
        break;
      case this.position.RIGHT:
        this.nameTypeWall = this.store.right;
        this.nameWall = this.store.side_open;
        break;
    }

    console.log('this.store.order.id', this.store.order.id)

    if (this.store.order.id === 0) {

      // použi defaultné data pre stenu, inak použi dáta z existujúcej obj
      if (this.$props.typeWall === this.position.LEFT || this.$props.typeWall === this.position.RIGHT) {
        if (this.opening === Models.Opening.SLIDING || this.opening === Models.Opening.COMBINED) {
          this.wall = {...this.store.walls_default[1]};       // bočné otváravé
          this.wall.position = this.$props.typeWall === this.position.LEFT ? this.position.LEFT : this.position.RIGHT
        } else {
          this.wall = {...this.store.walls_default[2]}       // bočné pevné
          this.wall.position = this.$props.typeWall === this.position.LEFT ? this.position.LEFT : this.position.RIGHT
        }
      } else {
        this.wall = {...this.store.walls_default[0]}
        console.log('calling from BKS Wall')
        if(this.store.type.code == Models.TypeCode.NB_F || this.store.type.code == Models.TypeCode.NB_F_L
            || this.store.type.code == Models.TypeCode.NB_F_R){
          this.wall.anotherProfilesThickness = 128
        }
      }      // predné

      this.wall.barrier = this.isBarrier
      this.wall.bks = !this.isBarrier
      this.key = this.store.order.walls.push(this.wall) - 1     // pridá stenu do order

      // pri BKS s opacnymi dverami je odpocitanie puzdra 128 a nie 170mm
      if (this.store.type.code == Models.TypeCode.NB_F_D){
        this.wall.anotherProfilesThickness = 128
      }

    } else {
      // potrebné vyhľadať stenu podľa opening a position
      let index = this.store.order.walls.findIndex(obj => obj.position === this.$props.typeWall)
      this.wall = this.store.order.walls[index]
      if (typeof this.store.order.walls[index] != "undefined") {
        this.opening = this.store.order.walls[index].opening
      }

      console.log('updated links')

      this.store.pdfProductionLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=screen'
      this.store.pdfProductionPrintLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=print'
      this.store.pdfBksProfileLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=screen'
      this.store.pdfBksProfilePrintLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=print'
      this.store.dxfGlassesLink = this.store.Api_url + '/dxf/bks/' + this.store.order.walls[index].id + '?format=download'

      console.log('this.pdfProductionLink', this.pdfProductionLink)

    }

    //console.log("wall:", store.glass_tempered_thickness)
  },

}
</script>

<style scoped>
.p-dropdown {
  height: 2.1rem;
  padding: 0.2rem;
}

.p-inputgroup-addon {
  padding: 1rem 0.5rem;
}

button {
  border-radius: 6px;
}

.p-button {
  display:inline-block;
  padding: 0.4rem 0.7rem;
  margin-bottom: 1rem;
}

</style>